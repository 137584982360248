<template>
    <v-container fluid>
        <v-row no-gutters>
            <v-col cols="12">
                <v-form class="custom-form" ref="appointmentForm" @submit.prevent="saveAppointment">
                    <v-card :elevation="1" color="grey lighten-2">
                        <v-row no-gutters>
                            <v-col cols="12">
                                <v-toolbar
                                    color="#546E7A"
                                    :elevation="1"
                                    height="30px"
                                    dark
                                >
                                    <v-toolbar-title class="subtitle-2"
                                        >Appointment Entry</v-toolbar-title
                                    >
                                </v-toolbar>
                                <v-card-text class="py-0 mt-1">
                                    <v-row dense>
                                        <v-col md="4" cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Patient</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        outlined
                                                        hide-details
                                                        @focus="$store.dispatch('patient/getPatients')"
                                                        :loading="$store.getters['patient/loading']"
                                                        :items="filteredPatients"
                                                        item-value="id"
                                                        item-text="display_text"
                                                        v-model="selectedPatient"
                                                        :rules="[v => !!v]"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3">Name</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :readonly="selectedPatient != null && selectedPatient.display_text != 'New Patient'"
                                                        v-model="newPatient.name"
                                                        :rules="[v => !!v]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3">Mobile</v-col>
                                                <v-col cols="9">
                                                    <v-text-field
                                                        dense
                                                        outlined
                                                        hide-details
                                                        :readonly="selectedPatient != null && selectedPatient.display_text != 'New Patient'"
                                                        v-model="newPatient.mobile"
                                                        :rules="[v => !!v]"
                                                    ></v-text-field>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col md="4" cols="4">
                                            <v-row no-gutters class="mb-1">
                                                <v-col cols="3" class="text-right pr-2">Doctor</v-col>
                                                <v-col cols="9">
                                                    <v-combobox
                                                        dense
                                                        v-model="selectedDoctor"
                                                        outlined
                                                        hide-details
                                                        @focus="$store.dispatch('doctor/getDoctors')"
                                                        :loading="$store.getters['doctor/loading']"
                                                        :items="$store.getters['doctor/doctors']"
                                                        item-key="id"
                                                        item-text="display_text"
                                                        :rules="[v => !!v]"
                                                    >
                                                    </v-combobox>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3" class="text-right pr-2">Apt. Date</v-col>
                                                <v-col cols="9">
                                                    <v-menu
                                                        v-model="menu"
                                                        :close-on-content-click="false"
                                                        :nudge-right="0"
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="auto"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                        <v-text-field
                                                            dense
                                                            outlined
                                                            hide-details
                                                            v-model="appointment_date"
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            autocomplete="off"
                                                        >
                                                            <v-icon slot="prepend-inner">mdi-calendar-month</v-icon>
                                                        </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="appointment_date"
                                                            @input="menu = false"
                                                        >
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </v-row>
                                            <v-row no-gutters>
                                                <v-col cols="3"></v-col>
                                                <v-col cols="9">
                                                    <v-row dense>
                                                         <v-col cols="6">
                                                            <v-btn @click="resetForm" height="28px" dark block color="deep-orange">Clear</v-btn>
                                                        </v-col>
                                                        <v-col cols="6">
                                                            <v-btn type="submit" :loading="waitingForSave" height="28px" dark block color="light-blue darken-2">Save</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col md="4" cols="4">
                                            <v-card
                                                outlined
                                                min-height="87"
                                            >
                                                <template v-if="showDoctorInfo.name">
                                                    <v-row no-gutters class="mx-2">
                                                        <v-col cols="10" class="pt-1">
                                                            <div class="subtitle-1">{{ showDoctorInfo.name }}</div>
                                                            <div class="caption">{{ showDoctorInfo.specialist != null ? `${showDoctorInfo.specialist} Specialist` : '' }}</div>
                                                        </v-col>
                                                        <v-col cols="2" class="pt-1">
                                                        <v-img
                                                                :src="`${apiBasePath}/${showDoctorInfo.image}`"
                                                                alt="John"
                                                                height="45"
                                                                width="60"
                                                            ></v-img>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row no-gutters class="mx-2 my-1">
                                                        <v-chip-group
                                                            active-class="primary--text"
                                                            column
                                                        >
                                                            <v-chip small v-for="(dayName, i) in showDoctorInfo.days" :key="i">
                                                                {{ dayName }}
                                                            </v-chip>
                                                        </v-chip-group>
                                                    </v-row>
                                                </template>
                                                <div v-else class="text-center pt-5">Doctor not selected</div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
        <v-row dense class="mt-1">
            <v-col cols="12" class="pb-0">
                <v-data-table
                    class="custom-data-table elevation-1"
                    dense
                    :headers="appointmentHeaders"
                    :loading="$store.getters['appointment/loading']"
                    :items="$store.getters['appointment/appointments']"
                    :search="appointmentSearch"
                >
                    <template v-slot:top>
                        <v-toolbar
                            dense
                            color="white"
                            :elevation="1"
                            height="30px"
                        >
                            <v-toolbar-title class="subtitle-2"
                                >Appointment List</v-toolbar-title
                            >
                            <v-divider class="mx-4" inset vertical></v-divider>
                            <v-form class="custom-form">
                                <v-text-field
                                    outlined
                                    dense
                                    hide-details
                                    placeholder="Search Appointment"
                                    append-icon="mdi-magnify"
                                    style="width: 300px"
                                    v-model="appointmentSearch"
                                >
                                </v-text-field>
                            </v-form>
                        </v-toolbar>
                    </template>
                    <template v-if="userType != 'user'" v-slot:[`item.action`]="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="editAppointment(item)"
                                    color="primary"
                                    v-on="on"
                                    >mdi-circle-edit-outline</v-icon
                                >
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-icon
                                    small
                                    @click="openDeleteDialog(item.id)"
                                    color="error"
                                    v-on="on"
                                    >mdi-delete-circle-outline</v-icon
                                >
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </template>
                </v-data-table>
            </v-col>
        </v-row>

        <delete-confirm ref="deleteDialog" @confirm="deleteBankTransaction"></delete-confirm>

    </v-container>
</template>

<script>
import swal from 'sweetalert'
import DeleteConfirm from '@/components/Confirm'

export default {
    name: "AppointmentEntry",
    components: {
        DeleteConfirm,
    },
    data: () => ({
        apiBasePath: window.apiBaseURL,
        menu: false,
        newPatient: {
            name: null,
            mobile: null,
        },

        selectedPatient: null,
        selectedDoctor: null,
        appointment_date: new Date().toISOString().substr(0, 10),
        
        appointmentHeaders: [
            { text: "Sl", value: "sl" },
            { text: "Date", value: "appointment_date" },
            { text: "Patient Name", value: "patient.name" },
            { text: "Mobile", value: "patient.mobile" },
            { text: "Doctor Name", value: "doctor.name" },
            { text: "Action", value: "action" },
        ],

        appointmentSearch: '',
        waitingForSave: false,
        appointmentEditId: null,
        appointmentDeleteId: null,

        showDoctorInfo: {
            name: null,
            specialist: null,
            image: null,
            days: null,
        },
        userType: ''
    }),

    watch: {
        selectedPatient: function (val) {
            if (val == null) return
            if (val.display_text == 'New Patient') Object.keys(this.newPatient).map(k => this.newPatient[k] = null);
            else Object.keys(this.newPatient).map(k => this.newPatient[k] = val[k]);
        },
        selectedDoctor: function (doctor) {
            if (doctor == null) return
            this.showDoctorInfo.name = doctor.name
            this.showDoctorInfo.specialist = doctor.specialist
            this.showDoctorInfo.image = doctor.image
            this.showDoctorInfo.days = String(doctor.days).split(',');
            this.showDoctorInfo.days = String(doctor.days).split(',')
        }
    },

    computed: {
        filteredPatients() {
            let arr =  [{id: null, display_text: 'New Patient'}]
            let _patients = this.$store.getters['patient/patients']
            if (this.appointmentEditId != null) return _patients
            return arr.concat(_patients)
        }
    },

    created() {
        this.$store.dispatch('appointment/getAppointments');
        let userData = JSON.parse(localStorage.getItem('_userData'))
        this.userType = userData.user_type     
    },

    methods: {
       async saveAppointment() {
            if (!this.$refs.appointmentForm.validate()) return
            this.waitingForSave = true

            let appointment = {
                appointment_date: this.appointment_date,
                doctor_id: this.selectedDoctor.id,
            }

            if (this.selectedPatient.display_text == 'New Patient') appointment.patient = this.newPatient
            else appointment.patient_id = this.selectedPatient.id

            if (this.appointmentEditId == null) {
                let res = await this.$store.dispatch('appointment/saveAppointment', {
                    url: 'add-appointment',
                    data: appointment
                })
                // if (res) this.resetForm()

                if(res.isSuccess) {
                    this.resetForm();

                    // swal({
                    //     title: 'Do you want to show invoice?',
                    //     icon: 'success',
                    //     buttons: true
                    // }).then(response => {
                    //     if (response) {
                    //         if (response) this.$router.push(`/appointment-invoice/${res.id}`)
                    //     }
                    // })
                }

            } else {
                let res = await this.$store.dispatch('appointment/saveAppointment', {
                    url: 'update-appointment',
                    data: {...appointment, id: this.appointmentEditId}
                })
                if (res) this.resetForm()
            }
            this.waitingForSave = false
       },

       editAppointment(item) {
           this.appointmentEditId = item.id
           this.selectedPatient = item.patient
           this.selectedDoctor = item.doctor
           this.appointment_date = item.appointment_date
       },

        openDeleteDialog(id) {
            this.appointmentDeleteId = id
            this.$refs.deleteDialog.dialog = true
        },

        deleteBankTransaction() {
            this.$store.dispatch('appointment/deleteAppointment', {id: this.appointmentDeleteId})
            this.$refs.deleteDialog.dialog = false
            this.appointmentDeleteId = null
        },

        resetForm() {
            this.selectedPatient = this.selectedDoctor = null
            this.newPatient.name = this.newPatient.mobile = null
            this.appointment_date = new Date().toISOString().substr(0, 10)
            this.appointmentEditId = null
            this.$refs.appointmentForm.resetValidation()
            Object.keys(this.showDoctorInfo).map(k => this.showDoctorInfo[k] = null)
        }
    }
};
</script>

<style lang="scss" scoped>
.v-icon.v-icon {
    font-size: 18px !important;
    top: -3px !important;
}
.v-icon.v-icon[data-v-1f38b4e5] {
    font-size: 14px !important;
    top: 0px !important;
}
.caption {
    line-height: 1 !important;
}
</style>